import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Table, message, Tag, Popconfirm, Image, Modal  } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import {
  EditOutlined
} from '@ant-design/icons';
import './monitoringManagement.less'

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};


function MonitoringManagement() {
  const [form] = Form.useForm();
  const [areaList, setAreaList] = useState([]);
  const [tableData, setTableData] = useState([])
  const [editInputValue, setEditInputValue] = useState('')

  const [videoIndex, setVideoIndex] = useState({})

  const [FormObject, setFormObject] = useState(null)

  const [IninialValues, setIninialValues] = useState({
    name: null,
    weigh: null,
    videoPolling: 2
  })


  const [isModalVisibleDeviceName, setIsModalVisibleDeviceName] = useState(false)

  const columns = [
    {
      title: '设备序列号',
      dataIndex: 'deviceSerial',
      align: 'center',
      // width: "220px"
    },
    {
      title: '设备名称',
      dataIndex: 'appName',
      align: 'center',
      // width: "300px"
    },
    {
      title: 'AppKey/AppId',
      dataIndex: 'appKey',
      align: 'center',
      // width: "300px"
    },
    {
      title: 'Secret/AppSecret',
      dataIndex: 'appSecret',
      align: 'center',
      // width: "300px"
    },
    {
      title: '设备类型',
      dataIndex: 'deviceType',
      align: 'center',
      render: (_) => {
        return (
          <p>{_ == 2? '萤石云': _==4? '大华': '未知'}</p>
        )
      }
    },
    {
      title: '设备状态',
      dataIndex: 'appSecret',
      align: 'center',
      width: "200px",
      render: (text, record, index) => {
        return record.status === 1 ? <Tag color="success">在线</Tag> : <Tag color="error">离线</Tag>
      }
    },
    {
      title: '操作',
      align: 'center',
      width: "200px",
      render: (text, record, index) => {
        return (
          <Popconfirm title="删除该设备？" onConfirm={() => { toDelete(record) }} >
            <span style={{ cursor: "pointer", color: "#1890ff", width: "168px" }}>删除</span>
          </Popconfirm>
        )
      }
    }
  ];

  const expandColumns = [
    {
      title: '通道号',
      dataIndex: 'channelNo',
      align: 'center',
      width: "100px"
    },
    {
      title: '设备名称',
      dataIndex: 'name',
      editable: true,
      render: (text, record, index) => {
        // console.log(record)


        

        return (

          <p style={{ display: 'flex', justifyContent: 'space-between' }}><span>{record.name}</span></p>
          // record.editable ?
          //   (<Input defaultValue={record.name} onChange={(e) => { toInput(e, record, index) }} onBlur={() => { toBlur(record, index) }} />) :
           
        )
      }
      // align: 'center',
    },

    {
      title: '封面图片',
      dataIndex: 'coverImg',
      align: 'center',
      width: "150px",
      render: (_) => {
        return (
          <Image
            width={200}
            src={_}
          />
        )
      }
    },

    {
      title: '权重',
      dataIndex: 'weigh',
      align: 'center',
      width: "150px"
    },

    {
      title: '启用',
      dataIndex: 'enable',
      align: 'center',
      width: "150px",
      render: (_) => {
        return (
          <p>{ _ === 3 ? <Tag color="error">否</Tag> : <Tag color="success">是</Tag>}</p>
         
        )
      }
    },

    {
      title: '大屏首页',
      dataIndex: 'videoPolling',
      align: 'center',
      width: "150px",
      render: (_) => {
        return (
          <p>{ _ === 2 ? <Tag color="error">否</Tag> : <Tag color="success">是</Tag>}</p>
         
        )
      }
    },

    {
      title: '设备状态',
      dataIndex: 'appSecret',
      align: 'center',
      width: "200px",
      render: (text, record, index) => {
        return record.status === 1 ? <Tag color="success">在线</Tag> : <Tag color="error">离线</Tag>
      }
    },
    {
      title: '操作',
      align: 'center',
      width: "200px",
      render: (text, record, index) => {
        // console.log(text, record, index)
        const detailData = JSON.stringify(record)
        return(
          <div>

            <Link style={{ color: "#1890ff", cursor: "pointer" }} target = "_blank" to={`/videoDetail?detailData=${encodeURIComponent(detailData)}`}>播放</Link>
             <span style={{ color: "#1890ff", marginLeft: 10, cursor: 'pointer' }} onClick={() => { toEdit(record, index) }}>编辑</span> 
              
            {/* <EditOutlined style={{ color: "#1890ff" }} onClick={() => { toEdit(record, index) }} /> */}
          </div>
        ) 
        
        
      }
    },
  ];

  const expandable = {
    indentSize: 0,
    // expandRowByClick: true,
    expandIconColumnIndex: 0,
    expandedRowRender: record => {
      return (
        <Table
          // showHeader={false}
          pagination={false}
          columns={expandColumns}
          dataSource={[...record.shiMany]}
        />
      )
    },
    expandIcon: ({ onExpand, record }) => {
      return <p style={{ cursor: "pointer", color: "#1890ff", width: "70px" }} onClick={e => onExpand(record, e)}>查看通道</p>
    }
  }

  const toEdit = (record, index) => {


    setVideoIndex(record)

    setIsModalVisibleDeviceName(true)


    let time =  setTimeout(() => {
      if(FormObject) {
        FormObject.resetFields()
      }
      clearTimeout(time)
    }, 200);

    


    // setTableData(data => {
    //   const editKey = record.key.split('')[0]
    //   return data.map(item => {
    //     if (item.key == editKey) {
    //       item.shiMany[index].editable = true
    //       return item
    //     }
    //     return item
    //   })
    // })
  }
  const toDelete = (record) => {
    // console.log(record)
    const requestData = {
      deviceSerial: record.deviceSerial,
      del: 2
    }
    React.$axios.post("saveShiApplication", requestData).then(() => {
      message.success('删除成功！');
      setEditInputValue('')
      getDeviceList();
    })
  }

  const toInput = (e) => {
    setEditInputValue(e.target.value)
  }

  const toBlur = (record, index) => {
    setTableData(data => {
      const editKey = record.key.split('')[0]
      return data.map(item => {
        if (item.key == editKey) {
          item.shiMany[index].editable = false
          return item
        }
        return item
      })
    })
    // console.log(editInputValue)
    if (editInputValue) {
      const requestData = {
        id: record.id,
        name: editInputValue
      }
      React.$axios.post("saveShi", requestData).then(() => {
        message.success('修改成功！');
        setEditInputValue('')
        getDeviceList();
      })
    }
  }

  const toSubmit = () => {
    form.validateFields().then(values => {
  
      if(!values.deviceType) {
        values.deviceType = 2
      }


      React.$axios.post("saveShiApplication", values).then(() => {
        message.success('操作成功！');
        getDeviceList();
      })
    })
  }

  const getAreaList = () => {
    React.$axios.post("areaAll").then(({ data }) => {
      setAreaList(data.areaAll)
    })
  };

  const getDeviceList = () => {
    React.$axios.post("listShiApplication", { logic: 4}).then(({ data }) => {

      setTableData(data.map((item, index) => {
        return {
          key: `${index + 1}`,
          deviceSerial: item.deviceSerial,
          appName: item.appName,
          appSecret: item.appSecret,
          appKey: item.appKey,
          status: item.status,
          deviceType: item.deviceType,
          shiMany: item.shiMany.map((value, key) => {
            return {
              ...value,
              key: `${index + 1}${key + 1}`,
              editable: false,
              editName: value.name,
              accessToken: item.accessToken
            }
          })
        }
      }))
    })
  };


  // 点击弹框OK
  function handleOkDeviceName(e) {
    FormObject.submit()
  }

  function handleCancelDeviceName() {
    setIsModalVisibleDeviceName(false)
  }


  function onFinish(value) {

    const requestData = {
      id: videoIndex.id,
      ...value,
      applicationId: videoIndex.applicationId,
      channelNo: videoIndex.channelNo,
      deviceSerial: videoIndex.deviceSerial,
    }

    React.$axios.post("saveShi", requestData).then(() => {
      message.success('修改成功！');
      setIsModalVisibleDeviceName(false);
      getDeviceList();
    })


  }


  useEffect(() => {
    getAreaList();
    getDeviceList();
  }, []);

  return (
    <div className="diy-container monitoringManagement">
      <div className="box add-box">
        <h3>添加监控</h3>
        <div className="box-content">
          <Form
            {...layout}
            form={form}
          >
            <Row>
              <Col flex="400px">
                <Form.Item
                  label="设备序列号"
                  name="deviceSerial"
                  rules={[
                    {
                      required: true,
                      message: '请输入名称',
                    },
                  ]}
                >
                  <Input placeholder="请输入名称" />
                </Form.Item>

                <Form.Item
                  label=" AppId/AppKey"
                  name="appKey"
                  rules={[
                    {
                      required: true,
                      message: '请输入AppId/AppKey',
                    },
                  ]}
                >
                  <Input placeholder="请输入AppId/AppKey" />
                </Form.Item>
                <Form.Item
                  label="Secret/AppSecret"
                  name="appSecret"
                  rules={[
                    {
                      required: true,
                      message: '请输入Secret/AppSecret',
                    },
                  ]}
                >
                  <Input placeholder="请输入Secret/AppSecret" />
                </Form.Item>
              </Col>
              <Col flex="400px">
                <Form.Item
                  label="设备名称"
                  name="appName"
                  rules={[
                    {
                      required: true,
                      message: '请输入设备名称',
                    },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>

                <Form.Item
                  label="级别"
                  name="level"
                  rules={[
                    {
                      required: true,
                      message: '请选择级别',
                    },
                  ]}
                >
                  <Select>
                    <Option value="1">一级</Option>
                    <Option value="2">二级</Option>
                    <Option value="3">三级</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="工区"
                  name="areaId"
                >
                  <Select>
                    {
                      areaList.map((item, index) => {
                        return (
                          <Option value={item.id} kye={index}>{item.name}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="400px">
                <div style={{marginTop: 112}}>
              <Form.Item
                  label="设备类型"
                  name="deviceType"
                >
                  <Select defaultValue={2}>
                  <Option value={2} >萤石云</Option>
                  <Option value={4} >大华</Option>
                  </Select>
                </Form.Item>
                </div>
     
              </Col>
              <Col flex={1}>
                <div className="submit-btn" onClick={() => { toSubmit() }}>提交</div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div className="box list-box">
        <h3>监控管理</h3>
        <div className="box-content">
          <Table
            bordered
            columns={columns}
            dataSource={tableData}
            expandable={expandable}
          />
        </div>
      </div>




      <Modal title="设备名称" visible={isModalVisibleDeviceName} onOk={handleOkDeviceName} onCancel={handleCancelDeviceName}>
        {/* <Input onChange={(e) => { toInput(e) }} /> */}

        <Form
            name="basic"
            
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            initialValues= {videoIndex}
            onFinish={onFinish}
            ref= {(el) => {setFormObject(el)}}
          >
            <Form.Item
              label="设备名称"
              name="name"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="权重"
              name="weigh"
            >
              <Input />
            </Form.Item>

            <Form.Item name="videoPolling" label="大屏首页">
              <Select>
                <Option value={3}>是</Option>
                <Option value={2}>否</Option>
              </Select>
            </Form.Item>      


            <Form.Item name="enable" label="启用">
              <Select>
                <Option value={3}>否</Option>
                <Option value={2}>是</Option>
              </Select>
            </Form.Item>     


          </Form>            





      </Modal>
                    





    </div>
  )
}

export default withRouter(MonitoringManagement);