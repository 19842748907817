import React, { useState, useEffect } from "react";
import { Link,  withRouter } from "react-router-dom";


import './Header.less';

import icon from '@/assets/img/newReviseImg/imgAndIcon/logo3.png';
import project from '@/assets/img/newReviseImg/imgAndIcon/project.png';
import man from '@/assets/img/newReviseImg/imgAndIcon/man.png';


function HomeRouter(props) {

const projectInfo =  React.$getStorage('projectInfo');
const AccessLogin = React.$getStorage('accessLogin');
const projectIdPermission = React.$getStorage('loginInfo').projectIdPermission == ''?false:true;
const projectIdPermissionParams = React.$getStorage('loginInfo')?.projectIdPermission;

const [projectName, setProjectName] = useState('');

const [allProjectList, setAllProjectList] = useState([]);
const [Name, setName] =  useState('1111');

  useEffect(() => {
    let currentName = React.$getStorage('loginInfo');
    getProjectWhereUser()
    setProjectName(projectInfo.name)
    setName(currentName.name)
  }, []);


  // 选择list
  const changeProject = (item) => {
    
    React.$axios.post('projectSwitchUser', {projectId: item.projectId}).then(res => {

        React.$setStorage('accessLogin', res.data.projectSwitchUser.accessLogin)
        React.$setStorage('loginInfo', res.data.projectSwitchUser)
  
        React.$axios.post('infoProject').then(({ data }) => {
          React.$setStorage('projectInfo', data.infoProject[0])
            window.location.reload();
        })
  
      })

  }

  // 点击退出登录
  const toReturn = () => {

    React.$removeStorage('loginInfo')
    React.$removeStorage('accessLogin')
    React.$removeStorage('LoginMsg')
    React.$removeStorage('projectInfo')
    window.location.href = '/login';
  }



  function getProjectWhereUser() {
    React.$axios.post('projectWhereUser').then(res => {
        setAllProjectList(res.data.projectWhereUser)
    })
  }


  return (
    <div className="HomeRouter">
        <div className="header">
            <div className="back"></div>
            <div className="back2"></div>


            <div className="buleLayout">
                {/* <img src={icon} alt="" /> */}
                <div className='projectDes'>
                  <p className='china'>企业级工程数字管理系统</p> 
                  <p className='english'>ENTERPRISE DIGITAL MANAGEMENT SYSTEM</p>
                </div>
                
            </div>

            
            <div className="redLayout">
                <div className="nav">
                  <div className="navWarp">
                    <img src={project} alt="" />
                    <p>{projectName}</p>

                    <ul>
                     <div className="move">
                         {
                            allProjectList.map((item, index) => {
                                return (
                                    <li key={index} onClick={() => {changeProject(item)}}>{item.nameProject}</li>
                                )
                            })
                         }
                     </div>
                    </ul> 
                    </div>   
                </div>


                <div className="selectPage">
                    <ul className="page">
                        <li className={props.index == 0?'active':''}><Link to="/HomeRouter"> 首页</Link></li>
                        <li className={props.index == 1?'active':''}><Link to="/panorama/720panorama">智慧建管中心</Link></li>
                        <li className={props.index == 2?'active':''}><Link to="/BIMCenter">BIM中心</Link></li>
                        <li className={props.index == 3?'active':''}><Link to="/GIScenter">GIS中心</Link></li>
                       
                        
                    </ul>
                    <ul className="control">
                        {
                          projectIdPermission?<li className={props.index == 4?'active':''}><a  target="_blank" href={"https://cgdls.1wx.cn/?projectIdPermission=" + projectIdPermissionParams}>企业级BI</a></li>:''
                        }

                        {/* {
                          projectIdPermission?<li className={props.index == 4?'active':''}><Link  target="_blank" to="/PageIndex2">企业级BI</Link></li>:''
                        } */}
                        <li><a href={'https://zhgdadmin.1wx.cn/login?accessLogin=' + AccessLogin} target="_blank">智慧工地BI</a> </li>
                        <li className="alertReturn">
                            <img src={man} alt="" /> {Name}
                            <div><p className="li" onClick={toReturn}>退出登录</p> </div>
                         </li>
                    </ul>
                </div>

            </div>

        </div>            
        {
            props.children
        }

    </div>
  )
}

export default withRouter(HomeRouter);